import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { ResetPasswordComponent } from "../../components/AuthComponent/ForgetPassword"
import { getParameterByName } from "../../services/utils"

const ResetPassword = () => {
  const [uid, setUid] = React.useState("")
  const [token, setToken] = React.useState("")
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setUid(getParameterByName("uid"))
    setToken(getParameterByName("token"))
  }, [typeof window !== `undefined` && window.location.href])

  React.useEffect(() => {
    if (uid && token) {
      setLoading(false)
    }
  }, [uid, token])

  return (
    <Layout noFooter>
      <SEO title="Lupa Password" />
      {!loading && <ResetPasswordComponent uid={uid} token={token} />}
    </Layout>
  )
}

export default ResetPassword
