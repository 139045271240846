// Generic imports
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useFormik } from "formik"
import { navigate } from "gatsby"

// Redux imports
import * as sessionSelectors from "../../../redux/slices/session/selectors"
import * as sessionThunks from "../../../redux/slices/session/thunks"

// Other components imports
import Input from "../../Reusable/Input"
import Button from "../../Reusable/Button"
import CardWrapper from "../card-wrapper"
import { Styles } from "./style"
import { Row, Col } from "react-bootstrap"
import { toast } from "react-toastify"
import { confirmResetPassword } from "../../../services/user"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {
  login: sessionThunks.login,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const ResetPassword = props => {
  const { user, uid, token } = props
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (user.email) {
      if (user.is_tutor) {
        setTimeout(() => navigate("/dashboard/tutor"), 2000)
      } else {
        setTimeout(() => navigate("/dashboard/courses"), 2000)
      }
    }
  }, [user])

  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: "",
      uid: uid,
      token: token,
    },
    onSubmit: values => {
      setLoading(true)
      confirmResetPassword(values)
        .then(resp => {
          setLoading(false)
          setSuccess(true)
          toast.success("Password berhasil diganti")
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          toast.error("Gagal mengganti password")
        })
    },
  })

  return (
    <CardWrapper current={"login"}>
      <Styles>
        <div className="backgroundBackCard">
          {success ? (
            <>
              <p>
                Password berhasil diganti. Sekarang kamu bisa login kembali 😄
              </p>
              <Button to="/login">Login</Button>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={6}>
                  <p className="judul-wrapper">Reset Password</p>
                </Col>
              </Row>

              <div className="input-wrapper">
                <Input
                  className="InputLogin"
                  type="password"
                  name="new_password1"
                  placeholder="Masukkan Password"
                  label="Password"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.new_password1}
                  autoFocus
                />
              </div>

              <div className="input-wrapper">
                <Input
                  className="InputLogin"
                  type="password"
                  name="new_password2"
                  placeholder="Masukkan Ulang Password"
                  label="Ulang Password"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.new_password2}
                />
              </div>

              <input
                type="hidden"
                name="uid"
                required
                onChange={formik.handleChange}
                value={formik.values.uid}
              />

              <input
                type="hidden"
                name="token"
                required
                onChange={formik.handleChange}
                value={formik.values.token}
              />

              <div className="button-wrapper">
                <Button
                  className="Button-Login"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "loading..." : "Ganti Password"}
                </Button>
              </div>
            </form>
          )}
        </div>
      </Styles>
    </CardWrapper>
  )
}

export const ResetPasswordComponent = withConnect(ResetPassword)
